class backTop {
  constructor() {
    this.init()
    
  }
  init(){
    // $('body').append('<div id="scrollTop" class="hvr-rectangle-out hvr-pop"><i class="icon-arrow-top"></i></div>');    //主站
    $('body').append('<div id="scrollTop" class="hvr-rectangle-out hvr-pop"><i class="icon-arrow-upward"></i></div>');   //子站
    
      $('#scrollTop').on('click', function () {
        $(document.body).velocity('scroll');
      });
    
      $(window).scroll(function () {
        _changeBackTop();
      });
    
      function _changeBackTop() {
        var oFooter = $('.copyright'); 
        var oFooterTop = oFooter.position().top;
        var stickyKitHeight = window.innerHeight * 1.2;
        var scrollTop = $(window).scrollTop(); //滚动高度
        if (scrollTop > stickyKitHeight) {
          if (!$('#scrollTop').hasClass('d-block')) {
            $('#scrollTop').addClass('d-block');
            $('#scrollTop').velocity('transition.bounceUpIn');
          }
          // var bottom = 30;
          // if (scrollTop + window.innerHeight > oFooterTop) {
          //   bottom = scrollTop + window.innerHeight - oFooterTop;
          // }
          $('#scrollTop').css('bottom', '65px');
        } else {
          $('#scrollTop').stop(true, false).fadeOut().removeClass('d-block');
        }
    
      }

  }


}

export default backTop