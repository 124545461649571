import mbNav from './Modules/mbNav'   //主站导航
// import mbNav from './Modules/mbNav-bible'   //子站导航
// import pcNav from './Modules/pcNav-bible'   //子站导航
import pcNav from './Modules/pcNav'   //主站导航
import stickNavbar from './Modules/stickNavbar'
// import navArticle from './Modules/ajax'
import search from './Modules/search'
import recommendApp from './Modules/recommendApp'  
import backTop from './Modules/back-top'  //里面有子站的修改
import PageScroll from './Modules/PageScroll'

class lesalutHeader {
    constructor(){
        this.init()
        
    }
    init(){
      $(window).width() < 1199.98 && new mbNav()
      new pcNav()
      new stickNavbar()
      // new navArticle()
      $(window).width() < 1199.98 && new recommendApp()
      new search()
      new PageScroll()
      new backTop()
        // this.navMbLayout()

    }

    // navMbLayout(){
        
    //     let mobileNavBtn = document.querySelector('.js-default-side-nav')
    //     let mobileSearchBtn = document.querySelector('.js-default-side-search')
    //     let offOn = false

    //     // 初始化侧边导航/
    //     $(mobileNavBtn).sideNav({
    //       edge: 'right',
    //       // menuWidth: 460,
    //       zIndex: 0,
    //       onOpen:  onOpena,
    //       onClose: onCloseb
    //     })
    //     // $('.header .nav').css('transform','translateX(100%)')
    //     // 给搜索按钮绑定点击事件
    //     $(mobileSearchBtn).on('click', ()=> {
    //       $(mobileNavBtn).trigger('click')
    //     })
    
    //     //  切换关闭、菜单按钮
    //     function onOpena(){
    //       $(mobileNavBtn).html(`<i class="icon-close"></i>`)
    //       $('.header .nav').css({
    //         'transform':'translateX(0)',
    //         'z-index':'9999999'
    //         })
    //       offOn = true
    //     }
    //     function onCloseb(){
    //         offOn = false
    //       $(mobileNavBtn).html(`<i class="icon-menu"></i>`)
    //       $('.header .nav').css('transform','translateX(100%)')
    //     }
    
    //     $(window).resize(()=> {
    //       if(window.innerWidth >1199 && offOn) {
    //         $(mobileNavBtn).trigger('click')
    //         offOn = false
    //       }
    //     })
    
    //   }
}

new lesalutHeader()