class pcNav {
  constructor() {
    this.init()
  }
  init() {
    this.menuArrow() // 给有子菜单的一级菜单添加箭头
    window.innerWidth > 1200 && this.__navList() // 获取4篇文章
    window.innerWidth > 1200 && this.nano()
  }

  // 给有子菜单的一级菜单添加箭头 
  menuArrow() {
    $('.pc_navbar>li').find('.taxonomy-content').map(function () {
      $(this).siblings('a').append('<i class="icon-expand-more"></i>')
    })
  }

  // 获取4篇文章
  __navList() {
    let falg = true
    let loading = $('.loading').html()
    $('.pc_navbar li a').on('mouseover', function () {
      // falg = false
      let cateId = $(this).data('id')
      $('.loading').css('display', 'flex');
      $('.taxonomy-items').html(loading)
      $.ajax({
        type: 'GET',
        url: NavAjax.ajaxurl,
        dataType: 'json',
        data: {
          action: 'nav_ajax',
          security: NavAjax.security,
          cateId: cateId,
          number: 4,
        // url: './js/Modules/ajaxPost.php',
       
        },
        success: function (data) {
          let articleDatas = data.data
          let articleTempl = []
          let levelHtml = ''
          let more = ''
          if (cateId == 6825) {
            let oldBible = ''
            let newBible = ''
            // let bibles = data.data
            let old_bibles = articleDatas.old
            let new_bibles = articleDatas.new

            $.map(old_bibles, (k, v) => {
              oldBible += `<a class="nav_bible ${k.bible_cat}" href="${k.bible_link}">${k.bible_name}</a>`
            })

            $.map(new_bibles, (e, j) => {
              newBible += `<a class="nav_bible ${e.bible_cat}" href="${e.bible_link}">${e.bible_name}</a> `
            })

            levelHtml = `<div class="old-testament">
                <h3>Ancien Testament</h3>
                <div class="chapters">
                ${oldBible}
                </div>
                </div>
                <div class="new-testament">
                <h3>Nouveau Testament</h3>
                <div class="chapters">
                ${newBible}
                </div>
                </div>`
            articleTempl.push(levelHtml)
          } else {

            $.map(articleDatas, (obj, index) => {

              if (obj.P_ID == 288 || obj.P_ID == 181 || obj.grandfather_ID == 181) {

                levelHtml = `<div class="nav-item illustrations">
                    <a href="${obj.link}" class="img-card">
                    <img src="${obj.image_large}" alt="${obj.alt}">
                    </a>
                    <h4><a href="${obj.link}">${obj.title}</a></h4>
                    <p class="duration">${obj.discription}</p>
                    </div>
                    `
              } else if (obj.P_ID == 88 || obj.grandfather_ID == 88) {
                levelHtml = `<div class="video-box-card nav-item">
                    <a href="${obj.link}" class="img-card video-card">
                        <img src="${obj.image}" alt="${obj.alt}">
                        <span class="duration"></span>
                        <span class="time">${obj.time}</span>
                    </a>
                    <a href="${obj.link}" class="title" title="${obj.title}">${obj.title}</a>
                    <p class="info">
                        <span class="date">${obj.date}</span>
                    </p>
                </div>
                    `

              } else {

                levelHtml = `<div class="nav-item">
                    <a href="${obj.link}" class="img-card">
                    <img src="${obj.image}" alt="${obj.alt}">
                    </a>
                    <h4><a href="${obj.link}">${obj.title}</a></h4>
                    <p class="duration">${obj.discription}</p>
                    </div>
                    `
              }
              articleTempl.push(levelHtml)

              // $('.taxonomy-items').html((articleTempl).join(''));

            })
            more = `<a href="${articleDatas[0].P_link}" class="read-more">Voir tout le contenu</a>`
          }

          $('.loading').css('display', 'none');
          $('.taxonomy-items').html((articleTempl).join(''));
          $('.taxonomy-items').append(more);
        },
        error: function (data) {
          console.log('no');
        }
      });
    })


  }

  // 二级菜单加滚动条
  nano() {
    $(".nano").nanoScroller({
      sliderMinHeight: 200,
      disableResize: true
    });
  }

}

export default pcNav