class stickNavbar {
  constructor() {
    this.init()
    this._headroomProduction()
  }
  init(){

//     //悬浮导航
// var stickNavbar = $('#stick-Navbar');
// var headNav = $('.header').children('.nav').clone(true);
// // var headNav = $('#header').clone(true);
// $('#stick-Navbar').append(headNav);
// // iTop = $('.header').outerHeight()
// // iTop = ($('.header').outerHeight()) * 2 || 110 * 2
// // console.log(iTop)


// new Headroom(document.querySelector("#stick-Navbar"), { //这里的nav-scroll改为你的导航栏的id或class
//   offset: 5, // 在元素没有固定之前，垂直方向的偏移量（以px为单位）
//   tolerance: 5, // scroll tolerance in px before state changes
//   classes: {
//     initial: "headroom", // 当元素初始化后所设置的class
//     pinned: "slideUp", // 向上滚动时设置的class
//     unpinned: "slideDown" // 向下滚动时所设置的class
//   }
// }).init();

// $(window).scroll(function () {
//   if ($(window).scrollTop() > 340) {
//     stickNavbar.hide();
//   } else {
//     stickNavbar.show();
//   }
// });

// // 到一定高度隐藏悬浮导航
// window.addEventListener('scroll', () => {
//   var scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
//   if (scrollTop > 150) {
//     $($myStickNav).show()
//   } else {
//     $($myStickNav).hide()
//   }
// })

    

  }


  _headroomProduction() {
    //创建悬浮导航盒子
    let oStickNav = document.createElement('div')
    oStickNav.setAttribute('id', 'stick-Navbar')
    oStickNav.classList.add('headroom')
    // document.body.appendChild(oStickNav)
    let sectionHeader = document.getElementsByTagName('header')[0]
    if(sectionHeader) {
      sectionHeader.appendChild(oStickNav)
    }else {
      return false
    }
    let $myStickNav = document.getElementById('stick-Navbar')
    
    // var stickNavbar = $('#stick-Navbar');
    
    //配置悬浮导航
    var headNav = $('.header').children().clone(true);
    $('#stick-Navbar').append(headNav);
    // let $navMenu = document.getElementById('nav')
    // let $navMenu = document.querySelector('.nav')
    // let $navMenuHtml = $navMenu.innerHTML;
    // $myStickNav.innerHTML = $navMenuHtml

    
    /**
    * [调用悬浮导航插件]
    * @type {Headroom}
    */
    
    // 供第三方判断导航状态的接口
    document.body.setAttribute('headroom_is_pin', 'false')
    /**
    * [headroom 悬浮导航插件]
    * @type {Headroom}
    */
    let oVelocity;
    var headroom = new Headroom($myStickNav, {
    onPin: () => {
    document.body.setAttribute('headroom_is_pin', 'true')
    $(window).trigger('_headroom_onpin')
    oVelocity = true
    },
    onUnpin: () => {
    document.body.setAttribute('headroom_is_pin', 'false')
    $(window).trigger('_headroom_onUnpin')
    },
    });
    headroom.init();
    
    // 到一定高度隐藏悬浮导航
    window.addEventListener('scroll', () => {
    var scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop > 171) {
    $($myStickNav).show()
    } else {
    $($myStickNav).hide()
    }
    })
    
    }


}

export default stickNavbar