class recommendApp {
  constructor() {
    this.init()
  }
  init(){

    //  如果未添加recommend html代码
  if ($('.js-recommend-app').length == 0) {
    return
  }
  
  //  如果为safari浏览器, (safari自带推荐)
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return
  }
  
  
  var oTarget = $('.js-recommend-app').eq(0)
  $('header').before(oTarget.removeClass('d-none'))
  
  if ('localStorage' in window) {
    try{
      if(window.localStorage.getItem('mb-app-closed')){
        oTarget.remove()
      }
    }catch (error){
    
    }
  }
  
  //  下載
  oTarget.on('click', '.download-btn', function () {
    if (B.isMobile() === 'android') {
      window.open(oTarget.get(0).dataset.android, '_blank')
    } else {
      window.open(oTarget.get(0).dataset.ios, '_blank')
    }
    // window.open(oTarget.get(0).dataset.appDownload)
  })
  
  oTarget.on('click', '.close-btn', function () {
    oTarget.css({
      transform: 'translateY(-100%)'
    })
    $(document.body).css({
      transition: 'all ease .4s',
      marginTop: -oTarget.outerHeight(true)
    })
    oTarget.on('transitionend', function () {
      oTarget.remove()
      $(document.body).css({
        transition: '0s',
        marginTop: 0
      })
      $(window).resize()
    })
    window.localStorage && window.localStorage.setItem('mb-app-closed','true')
  })
    

  }
}

export default recommendApp