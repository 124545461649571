class mbNav {
  constructor() {
    this.init()
  }
  init() {
    this.navMbLayout()    //  调取 移动端导航弹出层
    this.mbNavbar() //  给移动端菜单添加icon
    this.showList() // 点击展开子菜单

  }

  // 调侧边导航
  navMbLayout(){
        
    let mobileNavBtn = document.querySelector('.js-default-side-nav')
    let mobileSearchBtn = document.querySelector('.js-default-side-search')
    let offOn = false

    // 初始化侧边导航/
    $(mobileNavBtn).sideNav({
      edge: 'right',
      // menuWidth: 460,
      zIndex: 0,
      onOpen:  onOpena,
      onClose: onCloseb
    })
    // 给搜索按钮绑定点击事件
    $(mobileSearchBtn).on('click', ()=> {
      $(mobileNavBtn).trigger('click')
    })

    //  切换关闭、菜单按钮
    function onOpena(){
      $(mobileNavBtn).html(`<i class="icon-close"></i>`)
      $('.header .nav').css({
        'transform':'translateX(0)',
        'z-index':'9999999'
        })
      offOn = true
    }
    function onCloseb(){
        offOn = false
      $(mobileNavBtn).html(`<i class="icon-menu"></i>`)
      $('.header .nav').css('transform','translateX(100%)')
    }

    $(window).resize(()=> {
      if(window.innerWidth >1199 && offOn) {
        $(mobileNavBtn).trigger('click')
        offOn = false
      }
    })

  }

  // 给移动端菜单添加icon
  mbNavbar() {
    let iconArr = ['icon-Bible', 'icon-vangile', 'icon-Mditation-du-jour', 'icon-Tmoignages', 'icon-Q_R-sur-la-foi', 'icon-queue_music-24px', 'icon-Vidos', 'icon-Images']

    $('.pc_navbar>li>a:not(.home)').map((ke, le) => {
      let elIcon = `<i class="${iconArr[ke]}"></i>`
      
      $(le).prepend(elIcon)
    })
  }

  // 点击展开子菜单
  showList() {
    $("pc_navbar>li").find('.taxonomy-content')
    $('.header .pc_navbar>li').map((k, v) => {
      // console.log(v)
      // if ($(v).hasClass('active')) {
      // }
      $(v).on('click', (e) => {
        if($(v).hasClass('active')) {
          $(v).removeClass('active')
        }else {
          $(v).addClass('active');
        }

        $(".taxonomy-menu li").on('click', (e)=>{
          e.stopPropagation();
        })

        $(v).find('.taxonomy-menu').removeClass('nano') // 移除滚动条字段class
        $(v).siblings('li').removeClass('active');
       
      })

    })
  }

}


export default mbNav