class search {
  constructor() {
    this.init()
  }
  init(){
    this.navSearch()
    this.searchFocus()

  }

  // 导航点击显示搜索按钮
  navSearch(){
    $('.nav_pc_search .search-i').on('click', ev=>{
      $('.nav_pc_search').addClass('w-search')
      $('.awesomplete').find('#countries').focus()   // 自动获取焦点
    })
    
    $('.pc_navbar .search-close').on('click', (ev)=>{
      ev.stopPropagation()
      $('.nav_pc_search').removeClass('w-search')
    })  


  }

  searchFocus(){
    let varkeyCode = ''
    $('#countries').on('keydown', function (e) {
      console.log(e.keyCode)
      varkeyCode = window.event ? event.keyCode : event.which;
      if (varkeyCode == 13) {
        search(); //搜索函数
      }
    });
  }

}

export default search