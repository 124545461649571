class PageScroll {
    constructor() {
        this.init()
    }
    init() {
        this.scrollReveal()
    }

    // 板块缓动动画
    scrollReveal() {
        window.sr = ScrollReveal({
            origin: 'bottom',
            scale: 1,
            distance: '30px',
            easing: 'ease-in-out',
            nav_mobile: false,
            mobile: false,
            viewOffset: {
                top: 150,
                right: 0,
                bottom: 30,
                left: 0
            },
        })


        // 
        let array = 'section:not(#header), .item, .imageCards_panel, .navTag, .navCat, .salut,  .imgAssociatedCate, .apps, h3:not(.mostShared_title), .s_imgs, .assist, .focusOn, .article_bg_card'
        // let array = 'section:not(#header), section:not(.imagesMain), section:not(#imageModal), h2, .item, .imageCards_panel, .navTag, .navCat, .imgAssociatedCate, .apps, h3, .s_imgs, .assist,  .sigle-video-card, .ad, .img-box-list, .focus-box-card, .img-box-list, .article_bg_card, .img-box-item, .slick-column '
        // let array = 'section:not(#header), .panel-body .slick-column, .ad'

        sr.reveal(array, {
            duration: 300,
        });

        /**
         * 
         */
        // console.log()
        // sr.reveal(".aside-card:nth(-n+6)", {
        //     duration: 300,
        // });

    }
}

export default PageScroll